export const environment = {
    production: false,
    currency: '',

    // PARAMETROS Greek Boys Choice Foods PRO
    firebase: {
        apiKey: "AIzaSyD4xkaZZklS8o7sNGH6tLU0OazWfBz2Q0A",
        authDomain: "ianos-app-us-greekboys-pro.firebaseapp.com",
        projectId: "ianos-app-us-greekboys-pro",
        storageBucket: "ianos-app-us-greekboys-pro.appspot.com",
        messagingSenderId: "363029428923",
        appId: "1:363029428923:web:766b7e7d8849824d63479e",
        measurementId: "G-0H2KQV81YB"
    },

    urlBase: 'https://us-central1-ianos-app-us-greekboys-pro.cloudfunctions.net',
    urlBaseWompy: 'https://us-central1-ianos-app-us-greekboys-pro.cloudfunctions.net/Wompi',
    apikey: '6dd5f108e1c-b704df14db02b-4171-5d6e24ef',
    googleMapsKey: 'AIzaSyCDk6GYGJWDeVPbgqlfOKGSXZ-9-eXBpW4',
};
